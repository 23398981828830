.xRoot {
  display:block;
  max-width:1600px;
  min-width:400px;
  margin:0 auto ;
}

html, body {
  margin:0;
  padding:0;
  font-family: 'Circe';
  font-weight: 400;
  background:none;
  color:#000;
  min-height:100%;
}

button {
  padding: 0;
  margin: 0;
}

p {
  margin:0;
  padding:0;
}

ul {
  margin:0;
  padding:0;
}

b {
  color: #333;
}

.table thead th {
  border-spacing: 0px;
}
/* html {
  background-image:url(./images/bg.jpg);
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
  background-attachment:fixed;
  font-size:16px;
} */





.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color:   #d7c7f3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.navigation-menu {
  display: flex;
}

.left-menu a,
.right-menu a {
  text-decoration: none;
  color: #000;
  padding: 0 10px;
  font-family: 'Circe', sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.navigation-logo {
  margin: 0 20px;
}

.navigation-logo img {
  width: 100px;
  height: auto;
}

.navigation-menu a {
  position: relative;
  transition: color 0.3s ease;
}

.navigation-menu a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navigation-menu a:hover,
.navigation-menu a:focus {
  color: #fff;
}

.navigation-menu a:hover::after,
.navigation-menu a:focus::after {
  transform: scaleX(1);
}

.main-content {
  position: relative;
  background-image: url('./img/mainpeople.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 300px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.content-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content-overlay {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}

.main-title {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 36px;
  margin: 0;
}

.main-subtitle {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 48px;
  margin: 20px 0;
}

.join-button {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 18px;
  background-color: #96c5f7;
  color: white;
  border: none;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  text-transform: uppercase;
  width: 200px;
  letter-spacing: 1px;
  border-radius: 20px;
  z-index: 2;
}

.join-button:hover {
  background-color: #d7c7f3;
  color: #000;
  transform: scale(1.05);
}

.main-content-wrapper {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 0px;
  box-sizing: border-box;
}

.content-image-wrapper {
  flex: 1;
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-image {
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  max-width: 600px;
  max-height: 600px;
}

.content-details-wrapper {
  flex: 2;
  order: 2;
  padding-left: 40px;
}

.content-details-title {
  font-family: 'Circe', sans-serif;
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #333;
}

.content-details-list {
  list-style: none;
  padding: 0;
  font-size: 20px;
}

.arrow-icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #96c5f7;
  margin-right: 8px;
  flex-shrink: 0;
}

.arrow-icon-wrapper img {
  width: 16px;
  height: 16px;
}

.content-details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.content-details-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content-button {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 16px;
  background-color: #96c5f7;
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  text-transform: uppercase;
  width: 150px;
  letter-spacing: 1px;
  border-radius: 20px;
  z-index: 2;
}

.main-content-button:hover {
  background-color: #d7c7f3;
  color: #000;
  transform: scale(1.05);
}

.stats-container {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(90deg, #96c5f7 0%, #d7c7f3 100%);
  padding: 20px 0;
  color: white;
}

.stat-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.stat-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stat-icon {
  width: 40px;
  height: 40px;
  fill: #fff;
}

.stat-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  margin-right: 10px;
}

.stat-number {
  font-family: "Open Sans";
  font-size: 54px;
  font-weight: bold;
}

.stat-label {
  font-size: 18px;
  font-family: 'Open Sans';
    font-weight: 700;
}

.articles-content-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  padding: 50px 0px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.article-button-wrapper {
  margin: 30px 0px;
}

/* страницы AboutUS */

.page-wrapper {
  padding: 30px 200px 60px 200px;
}

.text-center {
  text-align: center;
}

.page-wrapper p:not(:last-child) {
  padding-bottom: 30px;
}

.about-us_img{
  position: relative;
  background-image: url('./img/aboutusimg.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 300px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.page-title {
  display: inline-flex;
  position: relative;
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #333;
  margin: 20px 0px 50px 0px;
}

.page-title::after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 5px;
  background-color: #96c5f7;
  border-radius: 1px;
  width: 100%;
  left: 0;
}

.page-text {
  font-family: 'Circe', Arial, sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 28px;
  line-height: 1.55;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.custom-list {
  list-style: none;
  padding-bottom: 30px;
}

.custom-list li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: 'Circe', Arial, sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 28px;
}

.custom-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: #96c5f7;
  border-radius: 50%;
}

/* Members */

.members_img {
  position: relative;
  background-image: url('./img/members.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 300px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.table {
	width: 100%;
	border: none;
	margin-bottom: 20px;
	border-collapse: separate;
  border-spacing: 0px;

}
.table thead th {
	font-weight: bold;
	text-align: left;
	border: none;
	padding: 10px 15px;
  font-family: 'Circe', Arial, sans-serif;
  color: #333;
	font-size: 30px;
	border-top: 4px solid #d7c7f3;
}

.table thead {
  background: #d7c7f3;
}


.table tr th:first-child, .table tr td:first-child {
	border-left: 4px solid #d7c7f3;
}
.table tr th:last-child, .table tr td:last-child {
	border-right: 4px solid #d7c7f3;
}
.table thead tr th:first-child {
	border-radius: 20px 0 0 0;
}
.table thead tr th:last-child {
	border-radius: 0 20px 0 0;
}
.table tbody td {
	text-align: left;
	border: none;
	padding: 10px 15px;
	vertical-align: top;
  font-family: 'Circe', Arial, sans-serif;
    font-weight: 300;
    color: #000000;
    font-size: 24px;
    line-height: 1.55;
    border-bottom: 2px solid #d7c7f3;
}

.table tbody tr:nth-child(even) {
	border-bottom: 4px solid #96c5f7;
}

.table tbody tr:last-child td{
	border-bottom: 4px solid #d7c7f3;
}

.table tbody tr:last-child td:first-child {
	border-radius: 0 0 0 20px;
}

.table tbody tr:last-child td:last-child {
	border-radius: 0 0 20px 0;
}

.table-animate-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.table-animate-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.table-animate-exit {
  opacity: 1;
  transform: translateX(0);
}

.table-animate-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 300ms, transform 300ms;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.pagination-controls button:hover,
.pagination-controls button:focus {
  color: #96c5f7;
  outline: none;
  transform: scale(1.2);
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* BoardMembers */

.person-info-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px 50px 50px 100px;
}

@media (max-width: 940px) {
  .person-info-container {
    padding: 20px 0px 30px 0px;
  }
}


.person-info-content {
  display: flex;
  align-items: center;
}

.person-info-image {
  /* flex: 1; */
}

.person-info-image img {
  width: 250px;
  height: auto;
}

.person-info-text {
  flex: 2;
  padding-left: 40px;
}

.main-text {
  display: inline-flex;
  position: relative;
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 30px;
  color: #333;
}

.main-text::after {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 4px;
  background: linear-gradient(90deg, #d7c7f3 0%, #96c5f7 100%);
  border-radius: 1px;
  width: 300px;
  left: 0;
}

.secondary-text {
  font-size: 16px;
  color: #8b8d94;
  padding-top: 20px;
}

/* Documents */

.documents_img {
  position: relative;
  background-image: url('./img/documentsimg.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 200px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}



.sub-title {
  font-family: 'Circe', sans-serif;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
}

.documents-wrapper {
  padding: 30px 200px 60px 200px;
}

.documents-wrapper p:not(:last-child) {
  padding-bottom: 20px;
}


.documents-text {
  font-family: 'Circe', Arial, sans-serif;
    font-weight: 300;
    color: #000000;
    font-size: 20px;
    line-height: 1.55;
}

.page-subtext {
  font-family: 'Circe', Arial, sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 28px;
  line-height: 1.55;
}

.link-title {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 36px;
  margin: 0;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
  position: relative;

}

.link-title:hover::before {
  background-color: rgba(0, 0, 0, 0.1);
}

.link-title:hover {
  color: #96c5f7;
  transform: translateY(-3px);
}

.statute-details-list {
  list-style: none;
  padding: 0;
  font-size: 20px;
  margin-bottom: 80px;
}

.statute-icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #96c5f7;
  margin-right: 8px;
  flex-shrink: 0;
}

.statute-icon-wrapper img {
  width: 16px;
  height: 16px;
}

.statute-details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  line-height: 1.5;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.statute-details-list li:hover {
  transform: translateX(5px);
}

.mt {
  margin-top: 190px;
}

.mb-60 {
  margin-bottom: 60px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-30 {
  padding-top: 30px;
}

/* Articles  */

.articles_img {
  position: relative;
  background-image: url('./img/ArticleImg/articles-img.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 300px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.articles-wrapper {
  padding: 30px 80px 60px 80px;
}

/* 
.articles-wrapper p:not(:last-child) {
  padding-bottom: 20px;
} */

.articles-content {
  padding: 20px;
}

.articles-header {
  text-align: center;
  margin-bottom: 30px;
}


.articles-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.article-preview {
  width: 30%;
  padding: 10px;

}

.article-image {
  width: 100%;
  height: 320px;
}

.article-sub-image {
  width: 100%;
  padding-bottom: 60px;
}

.article-info {
  margin-top: 10px;
}

.article-title {
  font-family: 'Circe', Arial, sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: #333;
  line-height: 1.17;
}

.article-summary {
  font-size: 24px;
  font-family: 'Circe', Arial, sans-serif;
    font-weight: 300;
    color: #000000;
    line-height: 1.55;
  margin: 20px 0;
}

.article-more {
  padding: 10px 20px;
  background-color: #96c5f7;
  color: #333;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.article-more:hover, .article-more:focus {
  background-color: #92c9ff;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.article-more:active {
  background-color: #7fb2e5;
  transform: translateY(-1px);
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}

@media (max-width: 1375px) {
  .article-preview {
    width: 27%;
    padding: 10px;

  }

  .article-image {
    width: 100%;
    height: 210px;
  }

  .article-title {
    font-size: 25px;
  }

  .article-summary {
    font-size: 20px;
  }
}

@media (max-width: 940px) {
  .articles-wrapper {
    padding: 30px;
  }

  .articles-list {
    gap: 20px;
  }

  .article-preview {
    width: calc(100% - 20px);
  }

  .article-image {
    width: 100%;
    height: auto;
  }
}


@media (max-width: 480px) {
  .articles-wrapper {
    padding: 15px;
  }



  .articles-list {
    gap: 15px;
  }

  .article-title {
    font-size: 24px;
  }

  .article-summary {
    font-size: 18px;
  }

  .article-more {
    padding: 8px 16px;
  }
}

/* ArticlePage */

.mb-30 {
  margin-bottom: 30px;
}

.articlepage-wrapper {
  padding: 150px 100px 60px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.articlepage-content p:not(:last-child) {
  padding-bottom: 30px;
}

.articlepage-content {
  max-width: 780px;
  font-family: 'Circe', Arial, sans-serif;
  font-weight: 300;
  text-align: start;
}

.articlepage-title {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 45px;
  margin-bottom: 60px;
  color: #333;
  text-align: center;
}

.articlepage-summary {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 60px;
  color: #333;
  text-align: center;
}

.article-meta {
  font-family: 'Circe', Arial, sans-serif;
  font-weight: 300;
  font-size: 18px; 
  color: #666; 
  margin-bottom: 20px;
  line-height: 1.5;
}

.fs-35 {
  font-size: 30px;
    line-height: 1.35;
}

.fs-25 {
  font-size: 28px;
    line-height: 1.55;
}

/* Awards */

.medal-text-left {
  text-align: start;
}

.awards_img {
  position: relative;
  background-image: url('./img/awardsImg.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 300px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.awards-title {
  font-family: 'Circe', sans-serif;
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #333;
}

.medal-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 50px 0px;
}

.medal-img img{
    width: 600px;
    height: auto;
}

/* Form */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.form-container {
  max-width: 400px;
  width: 90%;
  margin: auto;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
}

.form-header h2 {
  text-align: center;
  font-family: 'Circe', sans-serif;
    font-weight: 600;
    font-size: 35px;
    text-transform: uppercase;
    color: #333;
  margin-bottom: 10px;
}

.form-header p {
  font-size: 20px;
  text-align: center;
  color: #666;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 8px;
  color: #495057;
  font-size: 16px;
}

.form-input {
  padding: 12px 20px;
  border: 2px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f8f9fa;
  transition: border-color 0.2s ease-in-out;
}

.form-input:focus {
  border-color: #d7c7f3;
  outline: none;
}

.form-submit-button {
  padding: 12px 20px;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 18px;
  background-color: #96c5f7;
  color: white;
  border: none;
  letter-spacing: 1px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.form-submit-button:hover, .form-submit-button:focus {
  background-color: #d7c7f3;
  color: #000;
  transform: scale(1.05);
}

.form-submitted-message {
  font-size: 24px;
  color: #38c172;
}

/* Contacts */

.contact-wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-container {
  width: 70%;
  padding: 20px;
  border-radius: 20px;
  border: 6px solid #d7c7f3;
}

.contact-title {
  font-family: 'Circe', sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 60px;
  color: #333;
}

.contact-heading {
  color: #333;
  font-family: 'Circe', sans-serif;
    font-weight: bold;
    font-size: 40px;
}

.contact-details {
  margin-top: 20px;
}

.contact-item {
  margin-bottom: 10px;
  font-size: 25px;
}

.contact-info {
  font-weight: bold;
  color: #333;
}

/* Footer */

.footer-container {
  background-color: #d7c7f3;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  margin-right: 20px;
}

.footer-logo img {
  height: 50px;
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-links a {
  text-decoration: none;
  color: #000;
  margin: 0 10px;
  font-family: 'Circe', sans-serif;
  font-size: 20px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #fff;
}
